import axios, { AxiosError, AxiosResponse } from 'axios';

import Logger, { LogLevel } from '@/utils/logger';
import getRoute             from '@/utils/routing';

interface PageRatingResponse {
    message: string;
    rating: {
        label: string;
        score: number;
        scoreRounded: number;
        count: number;
    };
}

interface PageRatingErrorResponse {
    message: string;
}

function errorIsPageRatingError(response: AxiosResponse | undefined): response is AxiosResponse<PageRatingErrorResponse> {
    return typeof response === 'object' &&
        'data' in response &&
        typeof response.data === 'object' &&
        'message' in response.data &&
        response.data.message;
}

window.addEventListener('load', () => {
    const ratingContainers = document.querySelectorAll<HTMLDivElement>('.rating');

    ratingContainers.forEach(ratingContainer => {
        const pidInputElement = ratingContainer.querySelector<HTMLInputElement>('input[name="pid"]');
        const aggregateRatingElement = ratingContainer.querySelector<HTMLDivElement>('.aggregateRating');
        const ratingMessageElement = ratingContainer.querySelector('.rating-message');
        if (!pidInputElement || !aggregateRatingElement || !ratingMessageElement) {
            return;
        }

        const ratingCountElement = aggregateRatingElement.querySelector('.ratingCount');
        const ratingLabelElement = aggregateRatingElement.querySelector('.ratingLabel');
        const ratingValueElement = aggregateRatingElement.querySelector('.ratingValue');
        if (!ratingCountElement || !ratingLabelElement || !ratingValueElement) {
            return;
        }

        const pid = parseInt(pidInputElement.value, 10);
        const stars = ratingContainer.querySelectorAll<HTMLDivElement>('.star-rating');

        stars.forEach(starEl => {
            const attribute = starEl.attributes.getNamedItem('rel');
            const starValue = parseInt(attribute?.value || '0', 10);

            starEl.addEventListener('click', () => {
                axios.post<PageRatingResponse>(
                    getRoute('api_v1_page_ratings_add_rating'),
                    {
                        pageId: pid,
                        rating: starValue,
                    },
                )
                    .then(response => {
                        const data = response.data;
                        ratingMessageElement.innerHTML = `<p class="bg-success"><i class="fa fa-check"></i> ${data.message}</p>`;

                        ratingCountElement.innerHTML = data.rating.count.toString();
                        ratingLabelElement.innerHTML = data.rating.label;
                        ratingValueElement.innerHTML = data.rating.scoreRounded.toString();

                        const scoreRounded = Math.round(data.rating.scoreRounded);

                        stars.forEach(el => {
                            el.classList.remove('star-off');
                            el.classList.remove('star-on');

                            const attr = el.attributes.getNamedItem('rel');
                            const val = parseInt(attr?.value || '0', 10);

                            if (scoreRounded >= val) {
                                el.classList.add('star-on');
                            } else {
                                el.classList.add('star-off');
                            }
                        });
                    })
                    .catch((error: AxiosError) => {
                        Logger.log(LogLevel.DEBUG, error.message);

                        const response = error.response;
                        if (errorIsPageRatingError(response)) {
                            ratingMessageElement.innerHTML = response.data.message;
                        }
                    });
            });

            starEl.addEventListener('mouseover', () => {
                let found = false;

                stars.forEach(el => {
                    el.classList.remove('star-hover');

                    if (!found) {
                        el.classList.add('star-hover');
                    }

                    const attr = el.attributes.getNamedItem('rel');
                    const value = parseInt(attr?.value || '0', 10);

                    if (starValue === value) {
                        found = true;

                        const titleElement =  aggregateRatingElement.querySelector<HTMLSpanElement>('span[rel=a-title]');
                        const starTitleValue = starEl.querySelector('a')?.attributes.getNamedItem('rel')?.value || '';
                        if (titleElement) {
                            titleElement.textContent = starTitleValue;
                        }
                    }
                });
            });

            starEl.addEventListener('mouseout', () => {
                stars.forEach(el => {
                    el.classList.remove('star-hover');

                    const titleElement =  aggregateRatingElement.querySelector<HTMLSpanElement>('span[rel=a-title]');
                    if (titleElement) {
                        titleElement.textContent = '';
                    }
                });
            });
        });
    });
});
